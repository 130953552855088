.navbar-container {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: #FFFFFF;
    align-items: center;
    justify-content:space-between;
    padding-right: 30px;
    padding-left: 26px;
}


.navbar-user-profile {
    width: 38px;
    height: 38px;
    border-radius: 50%;

}

.navbar-user-name {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #1F4173;
    margin-left: 10px;
    margin-right: 4px;
    
}

.navbar-user-profile-name-container{
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
}
.custom-dropdown{
    display: flex;
    font-size: 10px;
    font-weight: 500;
    color:#1F4173 ;
    position: absolute;
    top: calc(100% + 1.25rem);
    background-color: #ffff;
    padding: 10px;
    border-radius: 10px;
    max-width: 400px;
    width: auto;
    min-width: 100px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    right: 0.317px;
}
.menu-profile {
    font-size: 14px;
    /* margin-left: 5px; */
    margin-right: 10px;
    margin-top: 2px;
    margin-bottom: 4px;
 

}
.menu-logout{
    font-size: 14px;
    /* margin-left: 10px; */
    margin-right: 10px;
}
.useroutline {
    margin-bottom: 10px;
}
.make-name-blur{
    
    color:#60728d
}
.donot-make-name-blur{
    color: #1F4173
}


