.pagination-container{
    display:flex;
    justify-content: flex-end;
    margin-top: 12px;
    margin-right: 15px;
}

.pagination-container-grid{
    display: grid;
    grid-template-columns: 26px auto 26px;
   
}

.pagination-prev-button{
    padding-top: 2px;
}

.navigation-btn.prev-disabled{
    /* border: 2px solid gray; */
    border: none;
    
}

.navigation-btn{
    background: none;
    border: none;
}

.pagination-content{
    margin-left: 15px;
    padding-top: 2px;
    
}

.pagination-btn{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align:center;
    /* border: 2px solid #003AD2; */
    color: #003AD2;
    border: none;
    height: 27px;
    width: 27px;
    border-radius: 50%;
    font: size 15px;
    font-weight:600;
    background-color:white;
    margin-right: 8px;
}

.pagination-btn-active{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align:center;
    /* border: 2px solid #003AD2; */
    border: none;
    color: white;
    height: 27px;
    width: 27px;
    border-radius: 50%;
    font: size 15px;
    font-weight:600;
    background-color:#003AD2;
    margin-right: 8px;
}

.pagination-next-button{
    padding-top: 2px;
}