@import url("https://cdn.syncfusion.com/ej2/material.css");
.backBtn {
  display: flex;
  align-items: center;
  color: #2a84b5;
  margin-bottom: 20px;
  font-weight: 600;
}

.upperRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 20px;
  align-items: center;
}

.upperRow h3 {
  font-weight: 600;
  font-size: 30px;
  line-height: 31px;
  color: #000000;
  margin: 0px;
  letter-spacing: 0.1px;
}

.weekTable {
  color: #1f4173;
  overflow: hidden;
  box-shadow: 0px 0px 8px 0px #dddddd;
  background-color: white;
  width: 100%;
  position: relative;
  top: 30px;
  border-radius: 1em;
}

.headRow {
  background-color: rgb(243, 243, 243);
  /* padding: 10px; */
  text-align: left;
  box-shadow: 0px 0px 5px 0px #afafaf;
}

.thead {
  text-align: left;
  display: inline-block;
  padding: 19px 10px;
  text-align: left;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.3px;
}

.tbody {
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  padding: 16px 10px;
  text-align: left;
  text-transform: capitalize;
}

.tfoot {
  background-color: rgb(243, 243, 243);
  /* box-shadow: 0px 0px 5px 1px #e7e7e7; */
  padding: 12px 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (max-width: 1450px) {
  .upperRow h3 {
    font-size: 23px;
  }

  .thead {
    padding: 15px 10px;
    font-size: 15px;
  }

  .tbody {
    font-size: 15px;
    padding: 13px 10px;
  }

  .tfoot {
    padding: 10px 0px;
  }

  .textarea {
    height: 45px !important;
  }

  .ant-input-affix-wrapper > input.ant-input {
    font-size: 14px;
  }

  .dropdown-toggle.projectHealthSelect {
    font-size: 16px;
  }

  .healthbtn {
    font-size: 14px;
    font-weight: 500 !important;
  }
}

@media screen and (max-width: 1450px) and (max-height: 800px) {
  .upperRow h3 {
    font-size: 22px;
  }

  .thead {
    font-size: 14px;
    padding: 10px 10px;
  }

  .tbody {
    font-size: 13px;
    padding: 13px 10px;
  }

  .tfoot {
    padding: 10px 0px;
  }

  .textarea {
    height: 40px !important;
  }

  .ant-input-affix-wrapper > input.ant-input {
    font-size: 12px;
  }

  .dropdown-toggle.projectHealthSelect {
    font-size: 13px;
  }

  .healthbtn {
    font-size: 14px;
    font-weight: 500 !important;
  }

  .status {
    font-size: 13px !important;
  }

  .searchLabel {
    font-size: 12px !important;
  }

  .optLabel {
    font-size: 12px !important;
  }

  .pagination_text {
    font-size: 13px !important;
  }

  .pagination button {
    font-size: 25px !important;
  }
}

@media screen and (max-width: 1250px) {
  .upperRow h3 {
    font-size: 22px;
  }

  .thead {
    padding: 15px 10px;
    font-size: 14px;
  }

  .tbody {
    font-size: 13px;
    padding: 10px 10px;
  }

  .tfoot {
    padding: 7px 0px;
  }

  .textarea {
    height: 40px !important;
  }

  .ant-input-affix-wrapper > input.ant-input {
    font-size: 12px;
  }

  .dropdown-toggle.projectHealthSelect {
    font-size: 15px;
  }

  .healthbtn {
    font-size: 14px;
    font-weight: 500 !important;
  }
}

@media screen and (max-width: 1100px) {
  .upperRow h3 {
    font-size: 20px;
  }

  .thead {
    padding: 15px 10px;
    font-size: 13px;
  }

  .textarea {
    height: 40px !important;
  }

  .dropdown-toggle.projectHealthSelect {
    font-size: 13px;
  }
}

.dropdown {
  width: fit-content;
}

.dropdown-item {
  padding: 10px 10px !important;
  background-color: #f0f2f5 !important;
  border-bottom: 1px solid #c7c3c3 !important;
}

.tbody .dropdown .dropdown-toggle::after {
  margin-left: 0.45em;
  vertical-align: 0.15em;
}

.dropdown-toggle.projectHealthSelect {
  cursor: pointer;
}

.dropdown-menu.show {
  padding: 0px !important;
}

.select {
  padding: 5px 10px;
  width: 210px;
  height: 40px;
  color: #8597b4;
  background-color: #e6e9f0;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.select ::placeholder {
  color: #8597b4;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.filter {
  display: flex;
  align-items: center;
}

.styleSearch {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin-left: 15px;
}

.searchLabel {
  color: #1f4173;
  width: 44px;
  height: 17px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
}

.searchBox {
  margin-left: 15px;
  width: 210px;
  height: 40px;
  color: #1f4173;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  background-color: #e6e9f0;
  border-radius: 10px;
}

.searchBox ::placeholder {
  color: #8597b4;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.ant-input-affix-wrapper-borderless,
.ant-input-affix-wrapper-borderless:hover,
.ant-input-affix-wrapper-borderless:focus,
.ant-input-affix-wrapper-borderless-focused,
.ant-input-affix-wrapper-borderless-disabled,
.ant-input-affix-wrapper-borderless[disabled] {
  background-color: #e6e9f0;
}

.dateFilter {
  display: flex;
  align-items: self-end;
}

.datepicker {
  border: none;
  outline: none;
  text-decoration: none;
}

.ant-input-affix-wrapper > input.ant-input {
  color: grey;
}

.e-input-group:not(.e-float-icon-left),
.e-input-group.e-success:not(.e-float-icon-left),
.e-input-group.e-warning:not(.e-float-icon-left),
.e-input-group.e-error:not(.e-float-icon-left),
.e-input-group.e-control-wrapper:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
  border: none;
  color: #305d9f;
  font-weight: 500;
  width: 290px;
}

.ant-input-affix-wrapper-borderless,
.ant-input-affix-wrapper-borderless:hover,
.ant-input-affix-wrapper-borderless:focus,
.ant-input-affix-wrapper-borderless-focused,
.ant-input-affix-wrapper-borderless-disabled,
.ant-input-affix-wrapper-borderless[disabled] {
  background-color: #e6e9f0;
}

.e-input-group-icon.e-range-icon::before,
*.e-control-wrapper .e-input-group-icon.e-range-icon::before {
  color: #305d9f;
}

.optLabel {
  font-weight: 600;
  display: flex;
  align-items: center;
  color: #1f4173;
  font-size: 14px;
  margin: 0 10px;
  /* padding-left: 20px; */
  /* padding: 0 0 12px 20px; */
  /* border-left: 2px solid rgb(170, 169, 169); */
}

.tableRow {
  border-bottom: 1px solid rgba(201, 201, 201, 0.4);
}

.tableRow:last-child {
  border-bottom: none;
}

.textarea {
  background-color: rgb(230 233 240);
  border-radius: 7px;
  border: none;
  resize: none;
  color: grey;
  height: 50px;
  width: 95%;
}

/* textarea.ant-input {
/* textarea.ant-input {
/* textarea.ant-input 
  width: 280px;
} */

.ant-input-affix-wrapper > input.ant-input {
  background: transparent;
}

.weekBtn {
  margin: 5px;
  border-radius: 9px;
  font-weight: 700;
  width: 90px;
  padding: 5px;
}

.textareaEdit {
  z-index: 3;
  width: 95%;
  border-radius: 10px;
  resize: none;
}

.engagement {
  display: -webkit-inline-box;
}

.healthbtn {
  width: 100%;
  border: none;
  padding: 10px 10px;
  align-items: center;
  display: flex;
  font-weight: 600;
  border-bottom: 1px solid #e1dddd;
  cursor: pointer;
}

.healthbtn:hover {
  background-color: #f3f3f3;
}

.ant-modal-close-x,
.ant-modal-footer {
  display: none;
}

.ant-modal-content {
  width: 141px;
  border-radius: 20px;
  position: relative;
  top: 20px;
  right: -341px;
}

.square {
  width: 15px;
  height: 15px;
  display: inline-block;
  margin: 0 10px 0 0;
  border-radius: 3px;
  vertical-align: middle;
}

.mainSquare {
  margin: 0 8px 0 16px;
}

.status {
  color: #1f4173;
  font-weight: 600;
  width: -webkit-fill-available;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: end;
}

/*---------------- PAGINATION BY ARJIT--------------------------*/

.pagination {
  border: 1px solid rgb(192, 191, 240);
  background-color: white;
  font-family: "Roboto", sans-serif;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px #a9a8a8;
  margin-right: 15px;
  align-items: center;
}

.pagination button {
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  box-sizing: border-box !important;
  line-height: 20px;
  text-align: center;
  padding-bottom: 8px;
  transition-duration: 0.3s;
  padding-right: 15px;
  padding-left: 15px;
  font-size: 30px;
  font-weight: bolder;
}

.pagination button:disabled {
  cursor: not-allowed;
  /* background-color: #ededed !important; */
  box-shadow: none !important;
}

.pagination button:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transform: scale(1);
}

/*---------------- PAGINATION DONE------------------------------*/
/*---------------- Custom Scrollbar------------------------------*/
::-webkit-scrollbar {
  width: 7px;
  border-radius: 20px;
}

/* ::-webkit-scrollbar-track {
  background-color: rgb(231, 231, 231);
} */

::-webkit-scrollbar-thumb {
  background-color: rgb(230 233 240);
  border-radius: 20px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #a0afcc;
}

/*---------------- Custom Scrollbar DONE------------------------------*/

/* responsive layout  */
@media screen and (max-width: 971px) {
  .upperRow {
    display: inherit;
  }
}

@media screen and (max-width: 855px) {
  .dateFilter {
    display: flex;
    align-items: self-end;
    justify-content: left;
    width: 60%;
  }

  .filter {
    display: inherit;
  }

  .status {
    text-align: left;
  }

  .optLabel {
    display: inline;
    margin: 0;
    padding-left: 0;
    border-left: none;
  }
}

@media screen and (max-width: 511px) {
  .filter {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
