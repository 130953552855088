.member-circles-container {
    display: grid;
    width: 100px;
    grid-template-columns: 26px 26px 26px 26px 26px;

}

.circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    grid-row: 1;
    grid-column: 1;
    border: 2px solid #FFFFFF;
    cursor: context-menu;
    color: #FFFFFF;
    text-align: center;
    padding-top: 4.5px;
}

.circle-one {
    background: #3C79E6;

}

.circle-two {
    background: #41E06F;
    margin-left: 25px;

}

.circle-three {
    background: #FF6C5F;
    margin-left: 49px;

}

.circle-four {
    background: #3BDEC1;
    margin-left: 73px;

}

.circle-five {
    background: #293042;
    margin-left: 96px;

}

.table-body-row-data-box {
    background: #F4F5F8;
    font-size: 14px;
    text-align: center;
    border-radius: 10px;
    width: 82px;
    height: 32px;
    padding-top: 7px;
}