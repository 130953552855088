
.loginBtn .loginBtn--google{
    width:100%;
    text-align: center;
    border: 2px solid #000;
    border-radius: 1em;
    box-shadow:unset ;
}

.title{
    font-size: 14px;
    color: #006EA9;
    margin: 1.2em 0 1em 0;
}
.border{
    padding:1em 0 1em 0;
}

.google-btn {
    width: 100%;
    height: 52px;
    cursor: pointer;
    box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);
    border: 2px solid #2E2E2E;
    box-sizing: border-box;
    border-radius: 10px;
}
    .google-icon-wrapper {
      position: absolute;
      margin-top: 1px;
      margin-left: 5px;
      width: 30px;
      height: 30px;
      border-radius: 2px;
      background-color: #fff;
    }
    .google-icon {
      position: absolute;
      margin-top: 11px;
      margin-left: 11px;
      width: 18px;
      height: 18px;
    }
    .btn-text {
      /* float: right; */
      margin: 14px 11px 0 0;
      /* color: #fff; */
      letter-spacing: 0.2px;
      font-family: "Roboto";
      text-align: center;
      font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;

    color: #3C3C3C;
    }
    /* &:hover {
      box-shadow: 0 0 6px #4285f4;
    }
    &:active {
      background:#1669F2;
    } */
  