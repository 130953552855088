.dashboard-header {
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  letter-spacing: -0.02em;
}

.dashboard-header-contain {
  display: flex;
}

.dashboard-datepicker {
  width: 160px;
  height: 40px;
  border-radius: 10px;
  background-color: rgb(231, 231, 231);

}

.cards-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 39px;
  padding-right: 25px;
}

.dashboard-cards {
  display: flex;
  flex-wrap: wrap;
  background-color: #FFFFFF;
  padding: 10px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}


.cards-heading {
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  color: #1F4173;

}

.cards-subheading {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #1F4173;

  opacity: 0.7;
}

.dashboard-cards-sub {
  margin-left: 86px;
}

.table-container {
  background-color: #FFFFFF;
  border-radius: 15px;
  margin-top: 20px;
  margin-right: 23px;
  
  
}

.dashboard-table-heading {
  padding-left: 20px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  padding-top: 25px;
  padding-bottom:15px;
}

.dashboard-table-button {
  width: 100%;
  color: #003AD2;

  font-weight: 600;
  border: none;
  background: #FFFFFF;
  border-radius: 15px;
  margin-top: 4px;
  margin-bottom: 14px;
}

@media only screen and (max-width: 1308px) {
  .dashboard-cards {
    margin-right: 10px;
    text-align: center;
  }

  .dashboard-cards-sub {
    margin-left: 1px;
    width: 100%;
  }
}