.ant-layout {
  padding: 0;
}

h6.tools-class {
  margin-top: 10px;
  color: #222222;
  letter-spacing: .2px;
  margin-left: 5px;
  margin-bottom: 0px;
  font-size: 19px;
  font-weight: 700;
}

@media screen and (max-width: 1450px) and (min-height: 801px) {
  .tool-title {
    font-size: 16px !important;
  }

  .tool-description {
    font-size: 14px !important;
  }

  h6.tools-class {
    font-size: 17px;
  }
}

@media screen and (max-width: 1450px) and (max-height: 800px) {
  .iiwzGF {
    padding: 1em 1.5em !important;
  }

  .tool-title {
    font-size: 14px !important;
  }

  .tool-description {
    font-size: 12px !important;
    letter-spacing: .2px !important;
  }

  h6.tools-class {
    font-size: 15px;
  }
}

@media screen and (max-width: 1200px) and (min-width: 280px) {
  .ant-layout {
    padding: 0px 0px 25px 0px;
  }

  .tool-title {
    font-size: 15px !important;
  }

  .tool-description {
    font-size: 12px !important;
  }

  h6.tools-class {
    font-size: 17px;
  }

}