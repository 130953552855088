.filterBy {
  margin-top: 25px;
  color: blue;
}
.filter-by {
  font-size: 14px;
  font-weight: 600;
}

.timesheet-tabs {
  display: flex;
  justify-items: center;
  justify-content: space-between;
  width: 70%;
  padding-top: 5px;
  padding-left: 3px;
}

@media only screen and (max-width: 1452px) {
  .timesheet-tabs {
    justify-content: initial;
  }

  .project-name {
    margin-right: 10px;
    margin-top: 5px;
  }

  .project-owner {
    margin-right: 10px;
    margin-top: 5px;
  }

  .project-engagement {
    margin-right: 10px;
    margin-top: 5px;
  }

  .project-status {
    margin-right: 10px;
    margin-top: 5px;
  }

  .button-go {
    margin-right: 10px;
    margin-top: 5px;
    border: none;
    box-shadow: none;
  }

  .button-go {
    margin-right: 10px;
    margin-top: 5px;
    border: none;
    box-shadow: none;
  }

  .switch-resources {
    margin-right: 10px;
    margin-top: 5px;
  }

  .export-to-excel {
    margin-right: 10px;
    margin-top: 5px;
    border: none;
    box-shadow: none;
  }

  .export-to-excel {
    margin-right: 10px;
    margin-top: 5px;
    border: none;
    box-shadow: none;
  }
}

.project-name {
  /* min-width: 130px; */
  height: 40px;
  border-radius: 10px;
  background-color: rgb(231, 231, 231);
  border: none;
  padding: 10px;
  font-size: 14px;
  /* font-weight: 600; */
  color: #1f4173;
  margin-right: 10px;
  outline: none;
}
.project-name :focus {
  border: none;
}

.project-owner {
  max-width: 203px;
  /* min-width: 130px; */
  height: 40px;
  border-radius: 10px;
  background-color: rgb(231, 231, 231);
  border: none;
  padding: 10px;
  font-size: 14px;
  /* font-weight: 600; */
  color: #1f4173;
  outline: none;
}

.project-engagement {
  max-width: 240px;
  /* min-width: 180px; */
  height: 40px;
  border-radius: 10px;
  background-color: rgb(231, 231, 231);
  border: none;
  padding: 10px;
  font-size: 14px;
  /* font-weight: 600; */
  color: #1f4173;
  outline: none;
}

.project-status {
  max-width: 120px;
  /* min-width: 100px; */
  height: 40px;
  border-radius: 10px;
  background-color: rgb(231, 231, 231);
  border: none;
  padding: 10px;
  font-size: 14px;
  color: #1f4173;
  outline: none;
}

.button-go {
  box-sizing: border-box;
  width: 60px;
  height: 40px;
  background-color: #1f4173;
  border-radius: 10px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  border: none;
  box-shadow: none;
}

.button-clear {
  box-sizing: border-box;
  width: 41px;
  height: 40px;
  /* background-color: #003AD2; */
  border-radius: 21px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  border: none;
  box-shadow: none;
}
.switch-resources {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  color: #1f4173;
  width: 65px;
}

.export-to-excel {
  box-sizing: border-box;
  /* min-width: 150px; */
  width: 120px;
  height: 40px;
  background-color: rgba(31, 65, 115, 0.5);
  border-radius: 10px;
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  margin-right: 24px;
  border: none;
  box-shadow: none;
}

.export-to-excel:disabled {
  box-sizing: border-box;
  min-width: 150px;
  width: 120px;
  height: 40px;
  background-color: rgba(31, 65, 115, 0.5);
  border-radius: 10px;
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  margin-right: 24px;
  border: none;
  box-shadow: none;
}
.disable-export-to-excel {
  box-sizing: border-box;
  min-width: 150px;
  width: 120px;
  height: 40px;
  background-color: rgba(31, 65, 115, 0.5);
  border-radius: 10px;
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  margin-right: 24px;
  border: none;
  box-shadow: none;
  cursor: not-allowed;
}

.horizontal-slidder {
  padding-bottom: 10px;
  overflow-x: auto;
  scrollbar-width: thin;
}

.horizontal-slidder thead th {
  position: sticky;
}

.horizontal-slidder::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.horizontal-slidder::-webkit-scrollbar {
  height: 10px;
  width: 6px;
  border-radius: 15px;
}

.horizontal-slidder::-webkit-scrollbar-track {
  background: #ffffff;
  margin-top: 20px;
  border-radius: 15px;
  margin-bottom: 1px;
}

.horizontal-slidder::-webkit-scrollbar-thumb {
  height: 165px;
  background: #e6eaf2;
  border-radius: 20px;
}

@media screen and (max-width: 1226px) {
  .project-name {
    width: 110px;
  }
  .project-owner {
    width: 125px;
  }
  .project-engagement {
    width: 150px;
  }
}
