.layout-navbar{
    width: 100%;
    height:60px;
    position: fixed;
    z-index: 1;
    box-sizing: border-box;
    box-shadow: rgb(0 0 0 / 15%) 0px 1px 3px 0px;
}

.layout-body-container{
    width:100%;
    height:100%;
    display:flex;
    padding-top: 58px;
}

.layout-sidebar-container{
    
    height:100%;
    position:sticky;
    top: 0;
    box-shadow: rgb(0 0 0 / 15%) 0px 1px 3px 0px;
    
    
}



.layout-content-container{
    width:100%;
    height:100%;
    padding-top:30px;
    padding-left: 25px;
    background-color:#F5F7FB;
    overflow:auto;
}