.timesheet-container {
  width: 100%;
  height: 100%;
}

.timesheet-back-button {
  display: flex;
  justify-items: center;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #003ad2;
  opacity: 0.7;
  cursor: pointer;
}

.back-arrow {
  width: 8.49px;
  height: 5.66px;
  font-size: small;
}

/* .back-to-dashboard {
  font-size: small;
} */

.timesheet-container-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 24px;
}

.timesheet-container-heading-title {
  height: 31px;
  width: 118px;
}

.hoursLogCalender {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 140px;
  height: 40px;
  border-radius: 10px;
  background: #eaeef4;
  color: #1f4173;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.table-container {
  background-color: #ffffff;
  border-radius: 15px;
  margin-top: 20px;
  margin-right: 23px;
}
