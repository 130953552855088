.layout-content-container {
  width: 100%;
  height: 100%;
  padding-top: 30px;
  padding-left: 25px;
  background-color: #f5f7fb;
  overflow-x: hidden;
}

.backBtn {
  width: 150px;
  height: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #003ad2;
  cursor: pointer;
  opacity: 0.7;
}

.heading {
  /* width: 1110px; */
  /* height: 31px; */
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #000000;
  text-transform: capitalize;
}

.headBox {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-gap: 2%;
  grid-row-gap: 30%;
  background: #ffffff;
  border-radius: 15px;
  padding: 20px;
  /* width: 1110px; */
  /* height: 100; */
}

.headBox div {
  /* width: 245px; */
  /* height: 18px; */
  /* font-style: normal; */
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #1f4173;
  margin-bottom: 10px;
}

.headBox div:nth-child(5) {
  width: 100%;
}

.headBox div > span {
  font-weight: 500;
}

.headBox label:nth-child(5) {
  width: 100vw;
}

.resourceTable {
  width: 1070px;
  height: 250px;
  background: #ffffff;
  box-shadow: 0px 4px 8px #d9ebf2;
  border-radius: 15px;
  margin-top: 30px;
  text-align: center;
}

.tableCaption {
  background: #fafafa;
  box-shadow: inset 0px -1px 3px rgba(0, 0, 0, 0.05);
  border-radius: 15px 15px 0px 0px;
  align-items: center;
  display: flex;
  width: 1070px;
  height: 80px;
}

.captionLeftArrow {
  margin-left: 10px;
  width: 35px;
  height: 25px;
  color: #003ad2;
  border: 1px solid #fafafa;
  cursor: pointer;
}

.tableCalender {
  color: #1f4173;
  font-size: 22px;
}

.tableDate {
  width: 350px;
  height: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #1f4173;
}

.addResourcesBtn {
  width: 150px;
  height: 40px;
  background: #003ad2;
  border-radius: 10px;
  border: none;
  outline: none;
  font-size: 14px;
  /* line-height: 17px; */
  color: #ffffff;
  /* margin-left: 475px; */
}

.approved {
  padding: 13px 4px;
  background: #d4fbd4;
  color: green;
}
.pending {
  padding: 13px 4px;
  background: #d4fbd4;
  color: #ff9900;
}

/* Modal */
Modal > .modal-content {
  border-radius: 15px;
  border: none;
  width: 400px;
  height: 230px;
  box-shadow: 0px 10px 30px rgb(0 0 0 / 7%);
}

.resModal {
  width: 400px;
  height: 230px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.07);
  border-radius: 15px;
}

.resModalHead {
  width: 396px;
  height: 60px;
  box-shadow: inset 0px -1px 3px rgba(0, 0, 0, 0.05);
  border-radius: 15px 15px 0px 0px;
}

.resModalHead > h5 {
  padding: 20px;
  width: 100%;
  height: 22px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #1f4173;
}

.resModalBody {
  padding: 20px 20px 50px 20px;
}

.resModalBody > h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  color: #1f4173;
}

.resModalInput {
  width: 260px;
  height: 44px;
  background: #f4f5f8;
  border-radius: 10px;
  font-size: 14px;
  line-height: 17px;
  color: #8a9bb6;
  border: none;
  outline: none;
}

.resModalBtn {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.resModalBtn > button:nth-child(1) {
  width: 80px;
  height: 40px;
  background: #1f4173;
  opacity: 0.5;
  border-radius: 10px;
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
}

.resModalBtn > button:nth-child(2) {
  width: 80px;
  height: 40px;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  background: #003ad2;
  border-radius: 10px;
  border: none;
  outline: none;
}

/* end */

.tableHeading {
  padding: 20px 25px;
}

.tableHeading > tr > th {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #1f4173;
}

.tableHeading > tr > th:nth-child(2) {
  padding: 0 10px;
}

.tableHeading > tr > th:nth-child(3) {
  padding-left: 250px;
}

.tableHeading > tr > th:nth-child(4) {
  padding-left: 170px;
}

.tableHeading > tr > th:nth-child(5) {
  padding-left: 170px;
}

.resourcesName {
  padding: 2px 25px;
}
.resourcesName > tr > td:nth-child(2) {
  padding: 0 10px;
  text-align: left;
}
.resourcesName > tr > td:nth-child(3) {
  padding-left: 250px;
}
.resourcesName > tr > td:nth-child(4) {
  padding-left: 220px;
}
.resourcesName > tr > td:nth-child(5) {
  padding-left: 200px;
}
.resourcesName > tr > td:nth-child(2) {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1f4173;
}

/* ////////////////////////////////////////// */

/* .build {
  background-color: lightgray;
  border-radius: 10px;
  width: 100px;
} */

.loggedHoursResourcesTable {
  background-color: #ffff;
  width: 100%;
  margin: 30px 0;
  border-radius: 16px;
  color: #1f4173;
  overflow: hidden;
}

.loggedHours_tdata {
  padding: 10px 15px;
}

/* for status text */
.centerPadding {
  text-align: center;
  width: 82px;
  /* height: 36px; */
  background: #f0f2f5;
  padding: 10px 4px;
  border-radius: 10px;

  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;

  color: #1f4173;
  opacity: 0.7;
}

.green {
  padding: 13px 4px;
  background: #d4fbd4;
  color: #16c31e;
}
.orange {
  padding: 13px 4px;
  background: #d4fbd4;
  color: #ff9900;
}

.loggedhourInput {
  background-color: #f0f2f5;
  border-radius: 7px;
  border: none;
  resize: none;
  width: 150px;
  height: 36px;

  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Primary */

  color: #1f4173;

  opacity: 0.7;
}

.loggedStatus {
  width: 137px;
  padding: 7px 24px;
  text-align: center;
}

.lastBtn {
  gap: 1em;
}
.lastBtn > button:nth-child(1) {
  width: 100px;
  height: 40px;
  background: #1f4173;
  opacity: 0.5;
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  border: none;
  outline: none;
  text-align: center;
  color: #ffffff;
}

.lastBtn > button:nth-child(2) {
  width: 100px;
  height: 40px;
  background: #003ad2;
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  border: none;
  outline: none;
  text-align: center;
  color: #ffffff;
}

.resourceSpan {
  color: white;
  background-color: #24d6a5;
  border-radius: 8px;
  font-size: 12px;
  padding: 0px 3px;
}

.deleteBtn {
  color: red;
  font-size: 20px;
  transition: 0.1s;
  cursor: pointer;
}

/* modal */
.modalSelect {
  width: 250px;
  height: 38px;
  background-color: #f4f5f8;
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #8a9bb6;
  border: none;
  outline: none;
}
