.table-container-wrapper {
  width: 100%;
  /* border-radius: 15px; */
  overflow-x: scroll;
}

@media only screen and (min-width: 980px) {
  .table-container-wrapper {
    width: 100%;
    /* border-radius: 15px; */
    overflow-x: hidden;
  }
}

.timesheet-table-container {
  width: 100%;
  border-radius: 15px;
  /* min-width: 735px; */
}

.timesheet-table-head {
  padding-right: 8px;
}

.table-head-data {
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  color: #1f4173;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.timesheet-table-body {
  max-height: 22rem;
  overflow-y: auto;
  scrollbar-width: thin;
}

.timesheet-table-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.timesheet-table-body::-webkit-scrollbar {
  height: 10px;
  width: 6px;
  border-radius: 15px;
}

.timesheet-table-body::-webkit-scrollbar-track {
  background: #ffffff;
  margin-top: 20px;
  border-radius: 15px;
  margin-bottom: 1px;
}

.timesheet-table-body::-webkit-scrollbar-thumb {
  height: 165px;
  background: #e6eaf2;
  border-radius: 20px;
}

.table-body-no-data {
  text-align: center;
  font-weight: 600;
  color: #1f4173;
  padding-top: 11px;
}

.table-body-data {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #627a9d;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-right: 15px;
  word-break: break-all;
}

.table-body-data-clickable {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #627a9d;
  padding-top: 15px;
  padding-bottom: 15px;
  word-break: break-all;
}
